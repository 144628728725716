:root {
  --crowdwave-blue: #216FF5;
  --crowdwave-blue-dark: #0D49A0;
  --crowdwave-blue-darker: #061E4D;
  --crowdwave-blue-light: #edf3ff;
  --crowdwave-green: #2f7d32;
  --crowdwave-purple: #a788fc;
  --crowdwave-purple-light: #ded4ff;
  --crowdwave-purple-lighter: #fcfaff;
  --crowdwave-orange: #f06c00;
  --crowdwave-gray: #949494;
  --crowdwave-gray-light: #f7f7f7;
  --crowdwave-gray-dark: #707070;
  --crowdwave-text-color-primary: #061E4D;
  --crowdwave-font-default: "Outfit", sans-serif;
}
html, body, #root, .dashboard {
  display: block;
  height: 100%;
  font-size: 16px;
}
body {
  margin: 0;
  font-family: var(--crowdwave-font-default);
  font-optical-sizing: auto;
  font-style: normal;
  color: var(--crowdwave-text-color-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
}
body * {
  /* only for font override */
  font-family: var(--crowdwave-font-default);
}
a:visited {
  color: #061E4D;
}
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.right-panel {
  max-width: 400px;
  padding: 0;
}
.right-panel .info-box {
  display: flex;
  flex-direction: row;
  background: var(--crowdwave-blue-light);
  border-radius: 20px;
  padding: 20px 15px;
  margin: 50px;
  margin-right: 0;
}
.info-box-icon {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background: var(--crowdwave-blue);
  margin-right: 10px;
}
.info-box-icon img {
  width: 20px;
  height: 20px;
  margin: 10px;
}
.dashboard-content {
  margin: 20px 7%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  flex-grow: 2;
}
.dashboard-content.survey-list {
  flex-direction: column;
}
.dashboard-content > div {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}
.dashboard-content .stepper {
  width: 100%;
  margin: 20px 0;
}
.dashboard-content .stepper > div {
  width: 50%;
}
.dashboard-content .step .MuiContainer-root {
  margin: 0;
  padding: 0;
}
.dashboard .btnblock {
  width: 100%;
  margin: 5px 0px;
  padding: 10px 7%;
  overflow-y: hidden;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background: #fff;

}
.btnblock button,
.dynamic-card-list button,
.dynamic-chip-list button {
  height: 35px;
  font-size: 15px;
  border-radius: 20px;
  text-transform: capitalize;
}
.btnblock button:disabled {
  background-image: none;
  background-color: #f4f4f5;
}

.btnblock button>span>svg {
  font-size: 15px !important;
}
button.crowdwave-blue {
  background-color: var(--crowdwave-blue);
  border-radius: 8px;
  box-shadow: none;
}

button.crowdwave-blue-light {
  background-color: #E4EEFF;
  border-radius: 8px;
  box-shadow: none;
  color: #0D49A0;
  border: 1px solid var(--crowdwave-blue);
}

button.crowdwave-blue-light:hover {
  background-color: #eff5ff;
  border-radius: 8px;
  color: #0D49A0;
  border: 1px solid var(--crowdwave-blue-dark);
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

code {
  font-family: "Inter", sans-serif;
}

.navigation-closed {
  background-color: #FFFFFF;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
  border-top: 1px solid #E4E4E4;
  padding: 50px 80px;
  position: fixed;
  z-index: 2;
  width: auto;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  display: none;
  margin-left: 257px;
}

ul.nav-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 20px 0;
}
ul.nav-list li {
  margin-bottom: 30px;
}
ul.nav-list li a {
  color: #fff;
  font-size: 18px;
  text-decoration: none;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
ul.nav-list li a:hover {
  text-decoration: underline;
}
ul.nav-list li a.active {
  text-decoration: underline;
}
ul.nav-list li a:hover {
  color: var(--crowdwave-blue-light);
  text-decoration: none;
}
ul.nav-list li a em {
  margin: 0 10px;
  position: relative;
  top: 2px;
  font-size: 16px;
  color: #a5adb5;
}

.logo {
  background: var(--crowdwave-blue);
  height: 70px;
  width: 256px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  font-size: 1.3em;
  text-align: center;
}

.logo img {
  width: 65%;
}

.expand-search {
  display: none;
}

.selectbox.active .expand-search {
  display: block;
}

/* Header */
header.header {
  /* fighting mui specificity here */
  background-color: var(--crowdwave-blue);
  display: block;
  width: 100%;
}
header.header .btn-default {
  color: #0D49A0;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 0 35px;
  position: relative;
  border: 1px solid var(--crowdwave-blue-dark);
  background-color: #E4EEFF;
  border-radius: 8px;
  height: 48px;
  display: flex;
  align-items: center;
}
header.header .btn-default:hover {
  background-color: var(--crowdwave-blue-dark);
  color:#fff;
}

header.header > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
header.header > div .account-menu {
  margin: 0 40px 0 10px;
}
/* menu that opens when you click account-menu icon */
#account-menu ul li a,
#help-menu ul li a {
  color: inherit;
  text-decoration: none;
  width: 100%;
}
header.header > div ul.nav-list {
  margin: 0;
  height: 70px;
  flex-grow: 2;
}
header.header > div ul.nav-list li {
  margin: 0 20px;
  display:flex;
}
header.header > div ul.nav-list li a
header.header > div ul.nav-list li a:visited {
  margin: 0;
  align-self: center;
  color: #fff !important;
}

/* footer */
.App > .footer {
  max-height: 100px;
  min-height: 100px;
  background: var(--crowdwave-blue-light);
  color: var(--crowdwave-blue-dark);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  margin-top: 20px;
  justify-content: center;
}
.footer > .footer-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5px;
}
.footer > .footer-row a {
  color: var(--crowdwave-blue-dark);
  margin: 0 10px;
}
.footer > .footer-row a:visited {
  color: var(--crowdwave-blue-darker);
}

.searchbox em {
  position: absolute;
  top: 10px;
  left: 12px;
}

.dashboard .back-btn svg {
  width: 16px;
  margin-right: 5px;
}

.dashboard .next-btn svg {
  width: 16px;
  margin-left: 5px;
}

.content {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.review-block {
  margin: 0 auto;
  width: 100%;
}
.stacked-top {
  border-radius: 20px 20px 0 0;
}
.stacked-bottom {
  border-radius: 0 0 20px 20px;
  border-top: none;
}
h1 {
  font-size: 34px;
  margin: 0;
  text-transform: capitalize;
}
h3 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 32px;
  font-weight: 600;
  margin: 0;
}

p, ul, li {
  font-size: 16px;
}

.add-more {
  color: var(--crowdwave-blue-dark);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  padding-left: 20px;
  position: relative;
  border: 1px solid var(--crowdwave-blue-dark);
  background-color: #E4EEFF;
  border-radius: 8px;
  height: 48px;
  display: flex;
  align-items: center;
}

.add-more span {
  display: inline-block;
  padding-left: 1px;
  font-style: italic;
}

.copy-success-snackbar,
.copy-success-snackbar div,
.copy-snackbar,
.copy-snackbar div,
.save-snackbar,
.save-snackbar div {
  margin: 0 auto;
}
.copy-success-snackbar,
.copy-success-snackbar div,
.save-snackbar div {
  background-color: green;
  color: #fff;
}
.copy-snackbar div {
  background: var(--crowdwave-blue-light);
  color: var(--crowdwave-blue-dark);
}
.copy-success-snackbar {
  padding: 10px 20px;
}
.copy-success-snackbar a {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}
.label,
div.label {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.d-flex.label {
  display: flex;
  justify-content: space-between;
}

.label em,
em.info {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  color: #fff;
  font-style: normal;
  background-color: #B0B0B0;
  display: inline-flex;
  justify-content: center;
  font-size: 9px;
  margin-left: 5px;
  position: relative;
  top:-12px;
}

.rankings {
  display: block;
  font-size: 14px;
  font-weight: 500;
  width: 72.5%;
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.rankings em {
  width: 12px;
  height: 12px;
  border-radius: 50px;
  color: #fff;
  font-style: normal;
  display: inline-block;
  background-color: #B0B0B0;
  display: inline-flex;
  justify-content: center;
  font-size: 9px;
  margin-left: 5px;
}

.rankings span:nth-child(2) {
  margin-left: 90px;
}

.rankings-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.rankings-box button:nth-child(1) {
  border-radius: 5px;
  border: 1px solid #B0B0B0;
  padding: 0 12px;
  height: 48px;
  font-size: 20px;
  color: #B0B0B0;
  background-color: #FFFFFF;
  width: 150px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rankings-box input:nth-child(2) {
  margin-left: 15px;
}

.rankings-button {
  border-radius: 5px;
  border: 1px solid #B0B0B0;
  padding: 0 12px;
  height: 48px;
  font-size: 32px;
  color: #0D49A0;
  /*white background*/
  background-color: #FFFFFF;
  width: 125px;
  cursor: pointer;
}

.rankings-button:hover {
  background-color: #E4EEFF;
}

.dashboard-content input {
  border-radius: 5px;
  border: 1px solid #B0B0B0;
  padding: 0 12px;
  height: 48px;
  font-size: 16px;
  color: #333;
  width: 100%;
  box-sizing: border-box;
}
/* label.MuiInputLabel-root.Mui-disabled,
.dashboard-content input.Mui-disabled {
  background-color: #dfdfdf;
} */
/* mui input placeholder text */
label.MuiInputLabel-root,
p.MuiFormHelperText-root {
  color: var(--crowdwave-gray);
}
/* label.MuiInputLabel-root.Mui-disabled {
  color: #f2f2f2
} */
.textField-box {
  margin-bottom: 20px;
}
label {
  background: #fff;
  text-transform: capitalize;
}

.textBox {
  /* display: flex;
  align-items: center;
  flex-wrap: wrap; */
  width: 80%;
}

.report-view {
  margin-top: 0px;
  margin-bottom: 20px;
}

.right-panel ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.right-panel ul li {
  border: 1px solid #E4E4E4;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  padding: 15px;
}

.right-panel ul li a {
  font-size: 16px;
  text-decoration: none;
  font-weight: 400;
}

.right-panel h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}

.right-panel ul li a em {
  position: absolute;
  top: 42%;
  right: 15px;
  opacity: 0;
}

.right-panel ul li:hover em {
  opacity: 1;
}

.content-top {
  margin-top: 40px;
}

.right-panel .MuiAccordion-rounded {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  margin: 0 !important;
}

.right-panel .MuiAccordion-rounded::before {
  display: none;
}

.right-panel .MuiAccordionSummary-content {
  margin: 0 !important;
}

.right-panel .MuiAccordionDetails-root {
  padding: 0;
}

.right-panel .MuiAccordionSummary-gutters {
  padding: 0;
  min-height: initial !important;
}

.bottom-block {
  padding: 25px;
  position: fixed;
  bottom: 0;
  width: 206px;
}

.bottom-block ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.bottom-block ul li {
  padding: 20px 0;
  border-bottom: 1px solid #1B1B1B;
  text-align: left;
}

.bottom-block ul li:last-child {
  border-bottom: 0;
}

.bottom-block ul li a {
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.bottom-block ul li a em {
  margin-right: 25px;
  position: relative;
  top: 2px;
}

.expand-block {
  width: 28px;
  height: 28px;
  background: #4D4D4D;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.segments-box {
  display: flex;
}

.segments-box {
  width: 80%;
  margin-bottom: 20px;
}

.segments-box input:nth-child(2) {
  width: 140px;
  margin-left: 15px;
}

.segments-box button:last-child {
  width: 140px;
  margin-left: 15px;
}

.segments-view {
  border-top: 0;
}

.segments-view ul {
  display: flex;
}

.segments-view ul li span {
  display: block;
}

.segments-view ul li {
  flex-wrap: wrap;
  display: block;
  font-size: 16px;
  font-weight: 400;
  padding: 15px;
  margin-right: 10px;
  width: 80%;
  display: flex;
  align-items: center;
}

.segments-view ul li span {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}

.segments-view ul li:last-child {
  margin-right: 0;
  width: 80px;
  text-align: center;
}

.improve-btn {
  border-radius: 8px;
  padding: 0 15px;
  font-size: 16px;
  color: #0D49A0;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 32px;
  border: 1px solid var(--crowdwave-blue-dark);
  width: 148px;
  margin-top: 50px;
}

.improve-btn em {
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.no-data-wrapper {
  display: none;
}

.no-data-wrapper h3 {
  text-transform: none;
  font-size: 20px;
  font-weight: 400;
  color: #A4A4A4;
  opacity: 1;
  text-align: center;
}

.no-data-wrapper em {
  margin: 16% 0;
  display: flex;
  align-items: center;
}

.heading {
  font-size: 12px;
  font-weight: 400;
  color: #5B6066;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;


}

.heading span {
  width: 80%;
  padding-right: 23px;
}

.heading span:last-child {
  width: 80px;
}

textarea {
  border: 1px solid #E4E4E4;
  padding: 15px;
  border-radius: 8px;
  box-sizing: border-box;
  height: 150px;
  width: 100%;
  resize: none;
  font-family: "Inter", sans-serif;
}

/* Step 3 */
.question-content {
  position: relative;
}

.question-content .improve-btn {
  position: absolute;
  right: 15px;
  bottom: 18px;
  margin-top: 0;
  cursor: pointer;
}

.upload-btn {
  display: flex;
  align-items: center;
  height: 36px;
  width: 165px;
  padding-left: 15px;
}

.upload-btn em {
  position: relative;
  top: 2px;
  margin-right: 10px;
}

.upload-block {
  border: 1px dashed #E4E4E4;
  height: 84px;
  font-size: 14px;
  color: #5B6066;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  display: none;
}

.bulk-upload {
  margin: 0px 0 40px
}

.bulk-upload.active .upload-btn {
  display: none;
}

.bulk-upload.active .upload-block {
  display: flex;
}

.info-text {
  border: 1px solid var(--crowdwave-blue-dark);
  background: var(--crowdwave-blue-light);
  padding: 10px;
  border-radius: 12px;
}
.upload-block .MuiButton-colorPrimary {
  background: none !important;
  box-shadow: none !important;
  color: var(--crowdwave-blue);
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
}

.upload-block .MuiButton-colorPrimary em {
  position: relative;
  top: -2px;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.upload-info-text th,
.upload-info-text td {
  border: 1px solid #E4E4E4;
  border-collapse: collapse;
  padding: 5px;
  font-size: 12px;
}

.close-icon {
  position: relative;
  right: 0;
  cursor: pointer;
  align-self: flex-end;
}

.selectbox .MuiFormControl-root {
  width: 100%;
  margin: 8px 0;
  height: 48px;

}

.selectbox .MuiSelect-select {
  padding: 12.5px 14px;
  color: #545454;
  font-size: 15px;
}

.selectbox .MuiInputBase-root {
  border-radius: 8px;
}

.selectbox .add-more {
  width: 140px;
}

.q2 {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 15px;
}

.q2 ul li {
  border: 0;
  padding: 0;
  font-size: 14px;
  color: #5B6066;


}

.rating {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #4D5B68;
  opacity: 0.3;
}

.rating span:last-child {
  text-align: right;
}

.q3 ul {
  flex-wrap: wrap;
}

.q3 ul li {
  margin-right: 20px;
  width: auto;
}

.q-block p {
  font-size: 16px;
  color: #545454;
  line-height: 24px;
}

/* Left Sidebar */

.left-sidebar {
  background: #2C2B2B;
  left: 0;
  top: 0;
  width: 256px;
  z-index: 2;
}
.content-top {
  max-height: 467px;
}

.review-block {
  background-color: #FFFFFF;
  padding: 25px;
  border-radius: 20px;
  margin-bottom: 40px;
  margin-top: 50px;
}

.review-col {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.review-col h4 {
  font-size: 24px;
  font-weight: 600;
  color: #161616;
  margin: 0;
}

.review-col a {
  font-size: 16px;
  font-weight: 600;
  color: var(--crowdwave-blue-dark);
  text-decoration: none;
}

.review-col span {
  font-size: 16px;
  color: #5B6066;
}

.col-3 span,
.col-3 h4,
.col-3 a {
  width: 33.33%;
}

.review-col h4:first-child,
.review-col > span:first-child {
  width: 60%;
}

.review-col.last-child {
  margin-top: 50px;
  margin-bottom: 30px;
}

.col-3 a {
  text-align: right;
}

.no-survey {
  text-align: center;
}

.no-survey h2 {
  font-weight: 700;
  margin-top: 3%;
  line-height: 42px;
}

.no-survey span {
  display: block;
  font-weight: 500;
}

/* Survey History */
span.status,
.user-surveys-table td.status span {
  background-color: var(--crowdwave-purple);
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  border-radius: 30px;
  padding: 5px 8px 5px 8px;
  display: inline-block;
  text-transform: capitalize;
  margin-right: 10px;
}
.dynamic-table-cell.status {
  min-width: 200px !important;
}
.dynamic-table-cell.question_type {
  text-transform: capitalize;
}
.MuiDataGrid-pinnedRows--top {
  border-bottom: 2px solid var(--crowdwave-gray);
}
/* template survey logo */
.template-logo {
  margin-right: 5px;
  background: var(--crowdwave-gray-light) !important;
  height: 26px !important;
  cursor: help;
}
.template-logo img {
  width: 18px;
  height: 18px;
}
.template-logo div {
  padding: 2px 0 0 0;
  width: 20px;
}
.template-logo span {
  padding: 0 5px 3px 5px;
  position: relative;
  top: 1px;
}
span.status.complete,
.user-surveys-table td.status span.complete {
  background-color: var(--crowdwave-green);
  color: #fff;
}

span.status.running,
.user-surveys-table td.status span.running {
  background-color: var(--crowdwave-orange);
  color: #fff;
}

span.status.error,
.user-surveys-table td.status span.error {
  background-color: #FF3B30;
  color: #fff;
}
.css-lnsnn8-MuiDataGrid-rowCount {
/* DT table row count */
padding: 0 20px;
}

.user-surveys-table .view-results {
  height: 36px;
  padding: 0 15px;
  border-radius: 10px;
}

.user-surveys-table .view-results.active {
  background-color: #E4EEFF;
  color: #0D49A0;
  border: 1px solid var(--crowdwave-blue-dark);
  cursor: pointer;
}

.user-surveys-table .download {
  display: flex;
  color: #A4ACB4;
  margin-top: 8px;
  font-weight: 400;
  align-items: center;
  cursor: pointer;
}

.user-surveys-table .download.active {
  color: var(--crowdwave-blue-dark);
}
.user-surveys-table .download em {
  margin-right: 8px;
  position: relative;
  top: 2px;
}
.user-surveys-table td .spinner {
  height: 30px;
  width: 30px;
}

/* step 3 / details page */
.report-preview span.question-type,
.report-preview ul.question-type-counts li {
  text-transform: capitalize;
}
.report-preview .details-chip {
  padding: 0 5px;
  white-space: nowrap;
}
.report-preview .details-chip span {
  width: auto;
  white-space: nowrap;
}

/* report summary / synthesis / results */
.review-col ul {
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight:bold;
}
.review-col ul li {
  margin-bottom: 10px;
}
.results-col h2 {
  margin-top: 20px;
}
.results-col ul {
  list-style: none;
  margin: 0 -10px;
  padding: 0;
  display: flex;
}

.results-col ul li {
  background-color: #fff;
  padding: 20px;
  font-size: 14px;
  color: #6C737F;
  display: inline-grid;
  align-items: center;
  margin: 0 10px;
  width: 25%;
}
.results-row {
  margin-top: 30px;
}
.results-row div {
  display:flex;
  flex-direction:column;
  align-items:stretch;
  width: 100%;
}
.results-row button {
  align-self: flex-start;
}
.results-row h4 {
  font-size: 24px;
  margin: 15px 0;
  font-weight: 500;
}
.results-row .results-audience-summary {
  flex-direction: row;
  width:fit-content;
}
.results-audience-summary > div {
  flex-grow: 0;
}
.result-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.result-header a {
  color: var(--crowdwave-blue-dark);
  text-decoration: none;
  cursor: pointer;
}
.result-header a:nth-child(2) {
  padding-right: 20px;
}
.result-header a em {
  margin-right: 8px;
  position: relative;
  top: 2px;
}
.result-header .tabs {
  font-size: 14px;
  color: #0D49A0;
  border-bottom: 2px solid var(--crowdwave-blue-dark);
  text-transform: none;
  border-radius: 0;
  padding: 0;
}
.chart-block {
  width: 100%;
  background-color: #fff;
  margin-top: 20px;
}
/* :( inexplicably applied mui-x setting that hides a stacked bar chunk, do not remove :( */
.bar-chart-pro-container svg clipPath {
  display: none;
}
/* */
.question-results-details {
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #cacfdb;
  margin: 10px 0;
}
.chart-header-question-type {
  font-size: 16px;
  margin: 0;
  text-transform: capitalize;
}
.chart-header-question-text {
  font-size: 18px;
}
/* chart tooltip */
.MuiChartsTooltip-table {
  max-width: 600px;
  white-space: wrap;
}
.MuiChartsTooltip-valueCell {
  text-align: right;
}
/* entire results block */
.results-block::-webkit-scrollbar {
    display: block;
}
/* entire synthesis block */
.results-block div h1 {
  margin: 0 0 20px 0
}
.summary-block h1 {
  font-size: 1.7em;
}
.summary-block h2 {
  margin: 20px 0;
  font-size: 1.5em;
}
.summary-block h3 {
  font-size: 1em;
}

.chart-container {
  box-sizing: border-box;
  display: block;
  width: 600px;
  margin-left: 25px;
}
/*  report Design B */
.design-b .report-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 0;
}
.design-b .report-controls .report-actions.btnblock button {
  margin: 0 7px;
}
.results-block.design-b h1 {
  margin: 0;
}
.design-b ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.design-b .summary-block {
  margin: 10px 0;
  display: inline-block;
}
.design-b h1 {
  color: #000;
}
/* DynamicTable component */
.dynamic-table-container {
  min-height: 200px;
  margin-top: 20px;
}
.dynamic-table-container div.dynamic-table-header {
  text-transform: capitalize;
}
.dynamic-table-container .MuiDataGrid-columnHeaders div div {
  background: #f2f2f2;
  color: #555;
}
.dynamic-table-container .MuiDataGrid-row .MuiDataGrid-cell,
.dynamic-table-container .MuiDataGrid-row .dynamic-table-cell.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
}
.dynamic-table-container .MuiDataGrid-row .MuiDataGrid-cell[data-field="actions"] {
  justify-content: flex-end;
}
.dynamic-table-container .MuiDataGrid-row .dynamic-table-cell.actions {
  padding: 0;
}

/* Bar Chart Pro component */
.bar-chart-pro-container {
  max-width: 90%;
  align-self: flex-end;
  overflow: visible;
}
.bar-chart-pro-container svg {
  height: 100%;
  overflow: visible;
}

/* Loading spinner */
.spinner {
  display: block;
  margin: 0 auto;
  width: 50px;
  height: 50px;
  border: 3px solid #E4EEFF;
  border-radius: 50%;
  border-top-color: var(--crowdwave-blue-dark);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
.dynamic-table-container .spinner {
  width: 25px;
  height: 25px;
  margin-left: 0;
}
.prog-bar-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 200px !important;
}
.prog-bar-wrapper .spinner {
  height: 10px;
  width: 10px;
  margin-left: 5px;
}
/* Create Survey stepper */
.stepper > div > div > div:first-child {
  padding-left: 0;
}
.MuiStepper-root .MuiStepConnector-horizontal span {
  border-width: 2px 0 0 0;
}
.MuiStepper-root .MuiStepConnector-horizontal.Mui-disabled span {
  animation-direction: reverse;
  border-color:#bdbdbd;
}
.MuiStepper-root .MuiStepConnector-horizontal.Mui-active span {
  animation: step_connector_animation 2s ease-in-out;
  -webkit-animation: step_connector_animation 2s ease-in-out;
  border-color: var(--crowdwave-blue);
}
.MuiStepper-root .MuiStepConnector-horizontal.Mui-active span svg {
  color: var(--crowdwave-blue);
}
.MuiStepper-root .MuiStepConnector-horizontal.Mui-completed span {
  border-color:#09993e;
}
.MuiStepper-root .MuiStepIcon-root.Mui-completed  {
  color: #09993e;
}

/* design toggle */
.design-toggle button {
  height:10px;
}

/* Card */
.MuiCard-root.simple-card {
  background-color: var(--crowdwave-gray-light);
  margin: 10px 0;
  box-shadow: none;
}
.MuiCard-root.simple-card ol li,
.MuiCard-root.simple-card ul li {
  padding: 5px 0;
}
.MuiCard-root.simple-card.simple-synthesis-block {
  background-color: var(--crowdwave-blue-light);
  font-weight: bold;
}
.MuiCard-root.simple-card.simple-synthesis-block .summary-block {
  max-width: 95%;
}
.MuiCard-root.simple-card.expanded-synthesis-block {
  background-color: var(--crowdwave-purple-lighter);
}
/* stats group */
.stats-group {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}
.stats-group .stats-count {
  flex-grow: 0;
  margin-right: 20px;
}
.stats-group .stats-count .stat {
  width: 175px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--crowdwave-gray-light);;
  text-align: center;
}
.stats-group .stats-count .stat p {
  vertical-align: middle;
  margin: 0;
  font-weight: bold;
  color: #000;
}
.stats-group .stats-count .stat .stat-value {
  font-size: 32px;
}
.stats-group .stats-count .stat .stat-label {
  font-size: 20px;
}
.stats-group .stats-list {
  flex-grow: 2;
}
.stats-group .stats-list .stats-list-title {
  font-size: 32px;
  font-weight: bold;
  color: #000;
  margin: 0;
}
.stats-group .stats-list .stats-list-audience {
  font-size: 20px;
  color: #000;
  margin: 10px 0;
}
.stats-group .stats-list .MuiStack-root {
  margin: 10px 0;
}
.stats-group .stats-list .chip {
  background: var(--crowdwave-blue);
  color: #fff;
  font-size: 13px;

}
.stats-group .stats-list .segment-label {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin: 20px 0 0 0;
}
.stats-group .stats-list .segment-size {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-top:8px;
}
.stats-group .stats-list .question-list-item {
  margin: 10px 0;
}
.stats-group .stats-list .question-list-type {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-bottom: 0;
}
.stats-group .stats-list .question-list-text {
  font-size: 16px;
  color: #000;
  margin: 5px 0 10px 0;
}
.stats-group .stats-list .question-list-option {
  font-size: 14px;
  color: #000;
  font-style: normal;
  background: var(--crowdwave-gray-light);
  padding: 5px;
  display: inline-block;
  margin: 0 5px 5px 0;
}
.stats-group .stats-list a {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
}
/* whats new modal */
.whats-new-content section {
  background: var(--crowdwave-blue-light);
  border-radius: 5px;
  padding: 10px 10px 1px 10px;
}
.whats-new-content h3 {
  color:#fff;
  background: var(--crowdwave-blue);
  border: 5px solid var(--crowdwave-blue);
  border-radius: 5px;
}
.whats-new-content ul {
  list-style: none;
  padding: 0 0 0 5px;
  margin-bottom: 30px;
}
.whats-new-content ul li {
  margin: 0 0 10px 0;
}
.whats-new-content ul li ul {
  margin: 5px 0 5px 20px;
}
.whats-new-content ul li ul li {
  list-style: disc;
}
/* DynamicCardList component */
.dynamic-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 15px;
}
.dynamic-card-list button,
.dynamic-card-list-controls button,
.dynamic-chip-list button {
  border-radius: 20px;
  margin-top: 5px;
  white-space: nowrap;
}
.survey-save-help-text {
  color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  position:relative;
  left: -7%;
  font-size: 14px;
}
.dynamic-card-list.has-error {
  background: #faf2f2;
}
.dynamic-card {
  margin: 10px 0;
  border: 1px solid #D2D2D2;
  background: #fff;
  border-radius: 10px;
  padding: 10px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dynamic-card.editing {
  border: 1px solid #216FF5;
}
.dynamic-card.has-error {
  border: 1px solid red !important;
}
.dynamic-card-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 10px;
}
.dynamic-card-title {
  font-size: 20px;
  font-weight: bold;
}
.dynamic-card-handle {
  display: flex;
  width: 100%;
  justify-content: center;
}
.dynamic-card-handle span {
  cursor: grab;
}
.dynamic-card-handle svg {
  transform: rotate(90deg);
}
.dynamic-card-actions {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
}
button.dynamic-card-action {
  color: var(--crowdwave-blue);
  cursor: pointer;
  margin-top: 0;
}
.dynamic-card-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  flex-grow: 2;
}
.dynamic-card-field {
  display: flex;
  flex-direction: column;
  margin: 10px 10px 10px 0;
  flex-grow: 2;
}
.dynamic-card .segment-view > span,
.dynamic-card .question-view > span {
  margin-bottom: 10px;
}
.dynamic-card .segment-view {
  display: flex;
  justify-content: space-between;
}
.dynamic-card .segment-view .segment-name {
  flex-grow: 2;
}
.dynamic-card .segment-view .segment-chip {
  margin: 0 5px 0 0;
}
.dynamic-card .question-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dynamic-card .question-view .question-type {
  text-transform: capitalize;
}
.dynamic-card .question-view .text-response {
  color: var(--crowdwave-gray-dark);
  padding-bottom: 5px;
  width: 50%;
  opacity: 0.6;
}
.dynamic-card .question-view .checkbox-array {
  display: flex;
  flex-direction: row;
}
.dynamic-card .question-edit .question-text-input {
  flex-grow: 2;
}
.question-info-text,
.question-info-text button {
  font-size: 14px;
  color: var(--crowdwave-gray);
  margin: 0;
  padding: 0;
}
.question-info-text button {
  margin: 0 5px;
  color: var(--crowdwave-blue);
  position:relative;
  top: -2px;
}
.question-info-text svg {
  /* ? icon */
  position: relative;
  top: 6px;
  margin-right:5px;
  color: var(--crowdwave-gray);
}
.question-info-text .question-info-example {
  display: block;
  font-weight: bold;
  margin-left: 30px;
}
.question-example-reveal {
  margin: 0 5px;
  color: var(--crowdwave-blue)
}
.dynamic-card-add-helper-text {
  margin: 5px 0;
  font-size: 14px;
  color: var(--crowdwave-gray)
}
/* dynamic chip list */
.dynamic-chip-list {
  display: flex;
  flex-direction: column;
}
.dynamic-chip-list .chip {
  background: var(--crowdwave-purple-light);
  margin: 5px;
}
.dynamic-chip-list .fake-chip {
  /* this is a p tag styled to look like a chip */
  background: var(--crowdwave-purple-light);
  border-radius: 20px;
  display: inline-block;
  font-size: 12px;
  margin: 5px;
  padding: 7px 10px;
  cursor: pointer;
}
.dynamic-chip-list .trait-input {
  flex-grow: 2;
}
p.reset-suggestions {
  font-size: 12px;
  line-height: 28px;
  margin-left: 10px;
  color: var(--crowdwave-gray);
  cursor: pointer;
}

/* fix for tiny field help text needing to be longer than the input itself */
#audience-size-input-helper-text {
  white-space: nowrap
}

/* mobile warning */
.alert.mobile-warning {
  text-align: center;
  justify-content: center;
  /* display: none; */
}
.alert.mobile-warning .MuiAlert-action {
  margin-left: 0;
}

/* admin view */
.admin.dashboard-content {
  flex-direction: column;
}
.admin.dashboard-content .admin-content p {
  margin: 5px 0;
}
.admin.dashboard-content .admin-content strong {
  text-transform: capitalize;
  margin-right: 10px;
}
.admin.dashboard-content .admin-content h3 {
  margin: 10px 0;
}
/* fake link */
.fake-link {
  text-decoration: underline;
  color: var(--crowdwave-blue);
  cursor: pointer;
}
/* Print view */
@media print {
  .header,
  .footer,
  .left-sidebar,
  a.results-copy,
  a.download,
  .design-toggle,
  .question-results-details button,
  .simple-synthesis-block svg,
  span.status {
    display: none !important;
  }
  #root {
    display: block !important;
    overflow-y: visible !important;
    height: fit-content !important;
  }
  .result-header h1,
  .results-row {
    margin: 0;
  }
  .results-row div h3 {
    margin: 0;
    background:#fff !important;
    color: #000 !important;
    border-bottom: 2px solid #333;
  }
  .bar-chart-pro-container,
  .results-row svg {
    margin: 0 !important;
    padding: 0 !important;
  }
  .results-row > div,
  .bar-chart-pro-container,
  .results-row svg {
    page-break-inside: avoid;
  }
  .review-block {
    margin: 0;
  }

}

@keyframes step_connector_animation {
	0%{
		width: 0%;
	}
	100%{
		width: 100%;
	}
}
@-webkit-keyframes step_connector_animation {
	0%{
		width: 0%;
	}
	100%{
		width: 100%;
	}
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}